<template>
  <div class="loader"
       :style="{width: loaderSize + 'px', height: loaderSize + 'px'}"></div>
</template>

<script>
export default {
  name: "DefaultSpinner",
  props: {
    loaderSize: {
      type: String,
      default: '22',
    }
  }
}
</script>

<style scoped lang="scss">
.loader {
  border: 3px solid var(--primary-default);
  border-bottom-color: var(--neutral-400);
  border-radius: 50%;
  display: inline-block;
  animation: rotation 1s linear infinite;
  cursor: pointer;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}
</style>