<template>
  <div class="chips-item-wrap">
    <template v-if="isSelected">
      <div class="chips-item"
           :class="[{selected: isSelected}, {'full-width': isSelected && isFrozen}, {inactive: isInactive}]">

        <template v-if="showTooltip">
          <custom-tooltip :style="{minWidth: '140px'}"
                          :classes="'icon settings-icon'"
                          text-align="center">
            <template v-slot:slotTrigger>
              <div class="content">
                <slot></slot>
              </div>
            </template>
            <template v-slot:slotContent>
              Added to Account
            </template>
          </custom-tooltip>
        </template>

        <template v-else>
          <div class="content">
            <slot></slot>
          </div>
        </template>

        <div class="amount"
             v-if="amount">
          <span>{{ amount }}</span>
        </div>

        <template v-if="selectable">
          <div class="delete-icon"
               v-if="isSelected && !isFrozen">
            <svg-icon @click="deleteChips"
                      icon="close"/>
          </div>

          <div class="add-icon"
               v-if="!isSelected && !isFrozen">
            <svg-icon icon="plus"/>
          </div>
        </template>

        <div class="delete-icon"
             v-else>
          <svg-icon @click="deleteChips"
                    icon="close"/>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="chips-item"
           @click="addChips"
           :class="[{selected: isSelected}, {'full-width': isSelected && isFrozen}, {inactive: isInactive}]">

        <custom-tooltip v-if="isSelected && isFrozen"
                        :style="{minWidth: '140px'}"
                        :classes="'icon settings-icon'"
                        text-align="center">
          <template v-slot:slotTrigger>
            <div class="content">
              <slot></slot>
            </div>
          </template>
          <template v-slot:slotContent>
            Added to Account
          </template>
        </custom-tooltip>

        <template v-else>
          <div class="content">
            <slot></slot>
          </div>
        </template>

        <div class="amount"
             v-if="amount">
          <span>{{ amount }}</span>
        </div>

        <template v-if="selectable">
          <div class="delete-icon"
               v-if="isSelected && !isFrozen">
            <svg-icon @click="deleteChips"
                      icon="close"/>
          </div>

          <div class="add-icon"
               v-if="!isSelected && !isFrozen">
            <svg-icon icon="plus"/>
          </div>
        </template>

        <div class="delete-icon"
             v-else>
          <svg-icon @click="deleteChips"
                    icon="close"/>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "CustomChips",
  emits: ['chip-deleted', 'chip-added'],
  props: {
    amount: {
      type: Number,
      required: false
    },
    selectable: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    isFrozen: {
      type: Boolean,
      default: false,
      required: false,
    },
    isInactive: {
      type: Boolean,
      default: false,
      required: false,
    },
    showTooltip: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    deleteChips() {
      this.$emit('chip-deleted');
    },
    addChips() {
      this.$emit('chip-added');
    },
  }
}
</script>

<style scoped lang="scss" src="./styles.scss"></style>