<template>
  <div class="custom-range-input"
       v-click-outside="outside"
       :class="[{'active' : isOpened}, {'bg-transparent': bgIsTransparent}, 'direction-' + direction]">
    <div class="select-styled"
         :class="{'filter-applied': filterApplied}"
         @click="selectDropdownClicked">
      <span id="selected-value">
        <slot></slot>
        <template v-if="filterApplied">:
          <strong>{{ appliedPreset.min }}-{{ appliedPreset.max }}</strong>
          <div class="icon"
               @click="cleanFilterClicked">
            <svg-icon icon="close" class="applied-icon"/>
          </div>
        </template>
      </span>
      <div v-if="!filterApplied"
           class="icon">
        <svg-icon icon="angle-down-solid"/>
      </div>
    </div>

    <div class="select-options-wrap"
         :style="{minWidth: minBlockWidth}">
      <div class="range-inputs">
        <div class="input range-min">
          <custom-input-counter :preset="+selectedPreset.min"
                                :input-data="+selectedPreset.min"
                                @input-counter-changed="inputCounterChanged($event, 'min')"
                                :min="minValue"/>
        </div>
        <div class="separator">—</div>
        <div class="input range-max"
             :style="{minWidth: customMaxWidth}">
          <custom-input-counter :preset="+selectedPreset.max"
                                :input-data="+selectedPreset.max"
                                @input-counter-changed="inputCounterChanged($event, 'max')"
                                :max="maxValue"
                                :custom-max-width="customMaxWidth"/>
        </div>
      </div>
      <div class="bottom-content">
        <div class="presets-block"
             v-if="!noPresets">

          <label class="presets-item"
                 :class="{checked: preset.checked}"
                 v-for="(preset, key) in presetsInnerData">
            <span class="preset-label">{{ key }}:</span>
            <span class="preset-value">{{ preset.min }}-{{ preset.max }}</span>
            <input type="radio"
                   name="preset-radio"
                   @change="presetChecked($event, preset)"
                   :checked="preset.checked"
                   :value="key">
          </label>
        </div>

        <base-button height="30px"
                     width="100%"
                     font-size="15px"
                     border-radius="4px"
                     @click="filterApplyClicked">Apply
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import CustomInputCounter from "@/components/Forms/CustomInputCounter";

export default {
  name: "CustomRangeInput",
  components: {
    'custom-input-counter': CustomInputCounter
  },
  props: {
    options: {
      type: Object
    },
    initialData: {
      type: Object,
      required: false,
    },
    bgIsTransparent: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String,
      default: 'down'
    },
    minWidth: {
      type: String
    },
    presetsData: {
      type: Object,
      default: () => ({
        low: {
          min: 5,
          max: 29
        },
        medium: {
          min: 30,
          max: 69
        },
        hard: {
          min: 70,
          max: 100
        }
      })
    },
    noPresets: {
      type: Boolean,
      default: false
    },
    minValue: {
      type: Number,
      default: 0,
    },
    maxValue: {
      type: Number,
      default: 100,
    },
    customMaxWidth: {
      type: String,
      default: '64px',
    },
    openedFromOutside: {
      type: Boolean,
    },
    closeDropdown: {
      type: Number,
      required: false
    },
    closeDropdownWithResetValues: {
      type: Number,
      required: false
    },
  },
  emits: ['filter-applied'],
  data() {
    return {
      isOpened: false,
      appliedPreset: {},
      checkedRadio: null,
      presetsInnerData: {},
      filterApplied: false,
      selectedPreset: {
        min: this.minValue,
        max: this.maxValue
      },
    }
  },
  methods: {
    inputCounterChanged(value, type) {
      const presetsObject = {...this.selectedPreset};
      presetsObject[type] = value;

      if (type === 'min') {
        if (presetsObject.min > presetsObject.max) {
          presetsObject.max = presetsObject.min;
        }
      }

      if (type === 'max') {
        if (presetsObject.max < presetsObject.min) {
          presetsObject.min = presetsObject.max;
        }
      }

      this.selectedPreset = {...presetsObject};
      this.cleanPresets();
    },
    presetChecked(e, preset) {
      for (const key in this.presetsInnerData) {
        this.presetsInnerData[key].checked = key === e?.target?.value;
      }
      this.selectedPreset = {...preset};
    },
    outside() {
      this.isOpened = false;
      this.setAppliedFilters();
    },
    setAppliedFilters() {
      let tempSelectedPreset = {};

      if (JSON.stringify(this.appliedPreset) === '{}') {
        tempSelectedPreset.min = this.minValue;
        tempSelectedPreset.max = this.maxValue;
        this.selectedPreset = {...tempSelectedPreset};
        this.cleanPresets();
      } else if (JSON.stringify(this.appliedPreset) !== '{}') {
        tempSelectedPreset.min = this.appliedPreset?.min;
        tempSelectedPreset.max = this.appliedPreset?.max;
        this.selectedPreset = {...tempSelectedPreset};

        for (const key in this.presetsInnerData) {
          if (this.presetsInnerData[key].min === tempSelectedPreset.min && this.presetsInnerData[key].max === tempSelectedPreset.max) {
            this.presetsInnerData[key].checked = true;
          } else {
            this.presetsInnerData[key].checked = false
          }
        }
      }
    },
    filterApplyClicked() {
      this.filterApplied = true;
      this.appliedPreset = {...this.selectedPreset};
      this.$emit('filter-applied', this.selectedPreset);
      this.isOpened = false;

      for (const key in this.presetsInnerData) {
        if (this.presetsInnerData[key].min === this.appliedPreset.min && this.presetsInnerData[key].max === this.appliedPreset.max) {
          this.presetsInnerData[key].checked = true;
        } else {
          this.presetsInnerData[key].checked = false
        }
      }
    },
    cleanFilterClicked() {
      this.cleanFilter();
      // this.cleanPresets();
      this.$emit('filter-applied', null);
    },
    cleanFilter() {
      setTimeout(() => {
        this.filterApplied = false;
        this.presetsInnerData = {...this.presetsData};
        this.presetChecked('null', {
          min: this.minValue,
          max: this.maxValue
        });

        this.cleanPresets();
      }, 150);

      this.cleanPresets();
    },
    cleanPresets() {
      const presetsData = {...this.presetsInnerData};
      for (const key in presetsData) {
        presetsData[key].checked = false;
      }

      this.presetsInnerData = {...presetsData};
      this.appliedPreset = {};
    },
    selectDropdownClicked(e) {
      if (e?.target?.classList?.contains('icon') || e?.target?.classList?.contains('applied-icon') || e?.target?.closest('.svg-icons')?.classList?.contains('applied-icon')) {
        if (this.isOpened) {
          this.isOpened = true;
        }
      } else {
        this.isOpened = !this.isOpened;
      }
    }
  },
  mounted() {
    let tempPresetsInnerData = {...this.presetsData};

    if (this.initialData?.min && this.initialData?.max) {
      let tempSelectedPreset = {...this.selectedPreset};
      tempSelectedPreset.min = +this.initialData?.min;
      tempSelectedPreset.max = +this.initialData?.max;

      for (const key in tempPresetsInnerData) {
        if (+tempPresetsInnerData[key]?.min === +this.initialData?.min && +tempPresetsInnerData[key]?.max === +this.initialData?.max) {
          tempPresetsInnerData[key].checked = true;
        }
      }

      this.filterApplied = true;
      this.appliedPreset = {...this.initialData};
      this.presetsInnerData = {...tempPresetsInnerData};
      this.selectedPreset = {...tempSelectedPreset};
    } else {
      if (this.openedFromOutside) {
        this.isOpened = true;
      }
    }

    if (this.presetsData) {
      this.presetsInnerData = {...this.presetsData};
    }
  },
  computed: {
    minBlockWidth() {
      const customMaxWidth = parseInt(this.customMaxWidth);
      const minWidth = parseInt(this.minWidth);
      if (customMaxWidth > 64) {
        const diff = customMaxWidth - 64;
        return minWidth + diff + 'px';
      }
      return this.minWidth;
    }
  },
  watch: {
    initialData(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.cleanFilter();
        this.cleanPresets();
      }
    },
    maxValue(newVal, oldVal) {
      this.selectedPreset.max = newVal;
    },
    closeDropdown(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.isOpened = false;
      }
    },
    closeDropdownWithResetValues(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.setAppliedFilters();
      }
    },
  }
}
</script>

<style scoped src="./styles.scss" lang="scss"></style>