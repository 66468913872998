import Tooltips from "../tooltips";

export const keywordsAutosuggestTableConfig = {
    headers: [
        {
            text: 'Title',
            value: 'suggest',
            sortBy: 'suggest',
            align: 'left',
            width: '60%',
            textWidth: '800px',
            sortable: true,
            dynamicTitle: true,
            headingClass: 'suggest-col',
        },
        {
            text: 'Popularity',
            mobileText: 'Popularity',
            value: 'sap',
            sortBy: 'sap',
            width: '40%',
            textWidth: '42%',
            align: 'right',
            sortable: true,
            headingClass: 'sap-col',
            tooltipWidth: '248px',
            tooltipText: Tooltips.SAP_AS_TOOLTIP
        },
        {
            text: '',
            value: 'selected',
            width: '68px',
            textWidth: '16px',
            align: 'right',
            sortable: false,
            groupAddDelete: true,
        },
    ],
    headersGp: [
        {
            text: 'Title',
            value: 'suggest',
            sortBy: 'suggest',
            align: 'left',
            width: '60%',
            textWidth: '800px',
            sortable: true,
            dynamicTitle: true,
            headingClass: 'suggest-col',
        },
        {
            text: 'Popularity',
            mobileText: 'Popularity',
            value: 'sap',
            sortBy: 'sap',
            width: '40%',
            textWidth: '42%',
            align: 'right',
            sortable: true,
            headingClass: 'sap-col',
            tooltipWidth: '248px',
            tooltipText: Tooltips.SAP_GP_TOOLTIP
        },
        {
            text: '',
            value: 'selected',
            width: '68px',
            textWidth: '16px',
            align: 'right',
            sortable: false,
            groupAddDelete: true,
        },
    ]
}