<template>
  <div class="custom-input-counter"
       :style="{maxWidth: customMaxWidth}">
    <input type="number"
           :min="min"
           :max="max"
           class="input-number"
           @keyup="inputChanged"
           v-model="inputValue">
    <div class="sort-icons-block">
      <svg-icon icon="arrow-solid-up"
                @click="incrementValue"
                class="icon icon-first"/>
      <svg-icon icon="arrow-solid-down"
                @click="decrementValue"
                class="icon"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputCounterOld",
  props: {
    inputData: {
      type: Number
    },
    preset: {
      type: Number
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    customMaxWidth: {
      type: String,
      default: '64px',
    },
  },
  data() {
    return {
      inputValue: 0
    }
  },
  methods: {
    inputChanged() {
      this.$emit('input-counter-changed', +this.inputValue);
    },
    incrementValue() {
      if (this.inputValue < this.max) {
        this.inputValue++;
      } else {
        this.inputValue = this.max;
      }

      this.$emit('input-counter-changed', this.inputValue);
    },
    decrementValue() {
      if (this.inputValue > this.min) {
        this.inputValue--;
      } else {
        this.inputValue = this.min;
      }
      this.$emit('input-counter-changed', this.inputValue);
    },
  },
  mounted() {
    if (this.inputData) {
      this.inputValue = this.inputData;
    }
  },
  computed: {},
  watch: {
    preset(val) {
      this.inputValue = this.preset;
    }
  }
}
</script>

<style scoped src="./styles.scss" lang="scss"></style>