<template>
  <div class="basic-accordion filters-accordion"
       :class="[{'active': isActive}, classes]">
    <div class="accordion-inner-content">
      <div class="header-block"
           @click="accordionTrigger">
        <slot name="header">
          <div class="value">
           Filters
          </div>
        </slot>
        <div class="icon-wrap"
             :style="{color: color}"
             v-if="caretVisible || !collapsed">
          <svg-icon icon="angle-down-solid"/>
        </div>
      </div>
      <div class="content-block"
           ref="contentBlock">
        <div class="content-items-list">
          <transition name="fade">
            <div class="content-item">
              <slot name="accordion-body">default body</slot>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FiltersDropdown",
  data() {
    return {
      isActive: false,
      fullHeight: 0
    }
  },
  props: {
    initialHeight: {
      type: String
    },
    collapsed: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#102148'
    },
    justify: {
      type: String,
      default: 'jcfs'
    },
    background: {
      type: String,
      default: '#ffffff'
    },
    caretVisible: {
      type: Boolean,
      default: false
    },
    dataUpdated: {
      type: Number,
      required: false
    },
    classes: {
      type: Array,
      required: false
    }
  },
  emits: ['dropdown-opened', 'dropdown-closed'],
  mounted() {
    this.fullHeight = this.initialHeight;
  },
  methods: {
    accordionTrigger(e) {
      e.stopPropagation();
      if (!e.target.classList.contains('sublist-item')) {
        this.isActive = !this.isActive;
        this.calculateHeight();
      }

      if (this.isActive === false) {
        this.$emit('dropdown-closed');
      }

    },
    cursorLeftTarget() {
      if (this.collapsed) {
        this.fullHeight = this.initialHeight;
        setTimeout(() => {
          this.isActive = false;
        }, 250);
      }
    },
    calculateHeight() {
      const initHeight = parseInt(this.initialHeight);
      if (this.isActive) {
        this.fullHeight = this.$refs.contentBlock.clientHeight + initHeight + 'px';
      } else {
        this.fullHeight = this.initialHeight;
      }
    }
  },
  watch: {
    initialHeight() {
      this.calculateHeight();
    },
    collapsed() {
      this.isActive = false;
      this.calculateHeight();
    },
    dataUpdated(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.isActive = false;
        this.calculateHeight();
      }
    }
  }
}
</script>

<style scoped lang="scss" src="./styles.scss"></style>