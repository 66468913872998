<template>
  <div class="search-languages-block" v-click-outside="outside">
    <custom-inline-search ref='selector'
                          :placeholder="computedPlaceholder"
                          @search-input-changed="searchInputChanged"
                          :with-result-slots="true"
                          :min-input-length="0"
                          :show-caret="true"
                          :full-width-content="true"
                          results-max-height="256px"
                          :initial-data="currentLanguage"
                          :config="computedSearchInputConfigs"
                          :search-results="languages">

      <template v-slot:heading-info>
        <template v-if="isLanguagesLoaded">
          <template v-if="multiselect && currentLanguage.length > 1">
            <span class="multiselect-title">
              {{ selectedCount }}/{{ totalCount }} locales
            </span>
          </template>
          <template v-else-if="currentLanguage.length > 0">
            <dynamic-image classes="country-flag"
                           :width="18"
                           :height="14"
                           :size="32"
                           :country-code="currentLocaleCountryCode?.countryCode"/>
            {{ currentLocaleCountryCode?.localeName }}
          </template>
        </template>
        <template v-else>
          <default-spinner/>
          <span class="languages-loader">
            {{ loaderText }}
          </span>
        </template>
      </template>

      <template v-slot:results>
        <template
          v-if="preparedLanguages?.popular?.length > 0 || preparedLanguages?.other?.length > 0">
          <div class="results-group"
               v-if="preparedLanguages?.popular?.length > 0">
            <div v-if="showGroupTitle" class="group-title">
              Popular in {{ currentCountryName }}
            </div>
            <div v-for="result in preparedLanguages?.popular"
                 @click="languageClicked(result.code, result.name)"
                 class="result-item">
              <input v-if="multiselect"
                     class="common-checkbox"
                     type="checkbox"
                     :id="'locale_checkbox_' + result?.code"
                     v-model="localesObject[result?.code]"
                     :disabled="!localesObject[result?.code] && isMaxSelectionReached"
                     :value="result?.code">
              <dynamic-image classes="country-flag"
                             v-if="localeCountryCode(result?.code)?.countryCode"
                             :width="18"
                             :height="14"
                             :size="32"
                             :country-code="localeCountryCode(result?.code)?.countryCode"/>
              <span class="item-name">{{ result?.name }}</span>
            </div>
          </div>

          <div class="results-group"
               v-if="preparedLanguages?.other?.length > 0">
            <div v-if="showGroupTitle" class="group-title">
              Other
            </div>
            <div v-for="result in preparedLanguages?.other"
                 @click="languageClicked(result.code, result.name)"
                 class="result-item">
              <input v-if="multiselect"
                     class="common-checkbox"
                     type="checkbox"
                     :id="'locale_checkbox_' + result?.code"
                     v-model="localesObject[result?.code]"
                     :disabled="!localesObject[result?.code] && isMaxSelectionReached"
                     :value="result?.code">
              <template v-if="localeCountryCode(result?.code)?.countryCode">
                <dynamic-image classes="country-flag"
                               :width="18"
                               :height="14"
                               :size="32"
                               :country-code="localeCountryCode(result?.code)?.countryCode"/>
              </template>
              <span class="item-name">{{ result?.name }}</span>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="results-group">
            <div class="text">No data found</div>
          </div>
        </template>
      </template>
    </custom-inline-search>
  </div>
</template>

<script>
import DefaultSpinner from "@/components/UI/DefaultSpinner/index.vue";
import CustomInlineSearch from "@/components/Forms/CustomInlineSearch/index.vue";
import {getLocaleCountryCode} from "@/configs/locales-flags";

export default {
  name: 'LanguageSelector',
  components: {
    DefaultSpinner,
    CustomInlineSearch
  },
  emits: ['language-changed'],
  props: {
    languages: {
      type: Object,
      default: {},
    },
    currentLanguage: {
      type: [String, Array],
      default: {},
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    currentCountryName: {
      type: String,
      default: '',
    },
    isLanguagesLoaded: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Choose language',
    },
    loaderText: {
      type: String,
      default: 'Loading languages...',
    },
    searchInputConfigs: {
      type: Object,
      default: null,
    },
    showGroupTitle: {
      type: Boolean,
      default: true,
    },
    maxSelection: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      searchInputValue: '',
      defaultSearchInputConfigs: {
        border: 'none',
        boxShadow: '0 8px 38px 0 rgba(184, 186, 229, 0.16)',
        height: '36px',
        width: '256px',
      },
      selectedLocales: [],
      localesObject: {},
    };
  },
  methods: {
    outside() {
      //this.$refs.selector.isOpened = false;
    },
    searchInputChanged(value) {
      this.searchInputValue = value;
    },
    localeCountryCode(code) {
      return getLocaleCountryCode(code);
    },
    languageClicked(code, name) {
      if (this.multiselect) {
        const index = this.selectedLocales.indexOf(code);
        if (index !== -1) {
          this.selectedLocales.splice(index, 1);
          this.localesObject[code] = false;
        } else {
          if (this.isMaxSelectionReached) {
            return;
          }

          this.selectedLocales.push(code);
          this.localesObject[code] = true;
        }

        this.$emit('language-changed', this.selectedLocales);
      } else {
        this.searchInputValue = '';
        this.$refs.selector.isOpened = false;
        this.$emit('language-changed', code, name);
      }
    }
  },
  computed: {
    selectedCount() {
      return this.selectedLocales.length;
    },
    totalCount() {
      return this.preparedLanguages.popular.length + this.preparedLanguages.other.length;
    },
    isMaxSelectionReached() {
      return this.maxSelection > 0 && this.selectedCount >= this.maxSelection;
    },
    preparedLanguages() {
      const tempArray = {...this.languages};
      const loweredInput = this.searchInputValue?.toLowerCase();
      return {
        popular: tempArray?.popular?.filter(language => language?.name?.toLowerCase()?.includes(loweredInput)),
        other: tempArray?.other?.filter(language => language?.name?.toLowerCase()?.includes(loweredInput)),
      }
    },
    currentLocaleCountryCode() {
      if (this.currentLanguage.length === 0) {
        return null;
      }

      let locale = this.currentLanguage;
      if (Array.isArray(this.currentLanguage)) {
        locale = this.currentLanguage[0];
      }

      return this.localeCountryCode(locale);
    },
    computedPlaceholder() {
      return this.isLanguagesLoaded ? this.placeholder : '';
    },
    computedSearchInputConfigs() {
      return this.searchInputConfigs ? this.searchInputConfigs : this.defaultSearchInputConfigs;
    },
  },
  watch: {
    localesObject() {
      let selectedLocales = [];
      Object.keys(this.localesObject).forEach((code) => {
        if (this.localesObject[code]) {
          selectedLocales.push(code);
        }
      });
      this.selectedLocales = [...selectedLocales];
    },
    currentLanguage() {
      if (this.currentLanguage.length === 0) {
        return null;
      }

      if (Array.isArray(this.currentLanguage)) {
        let localesObject = {};
        this.currentLanguage.forEach((code) => {
          localesObject[code] = true;
        });
        this.localesObject = localesObject;
      }
    },
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>
